import {
  AutoDiffChangeableSettingsModule,
  CandleCloserChangeableSettingsModule,
  DecimalsChangeableSettingsModule,
  PairChangeableSettingsModule,
  PeriodChangeableSettingsModule,
  SpreadDecompressionChangeableSettingsModule,
  TradeChangeableSettingsModule,
  VerifyOrderChangeableSettingsModule,
  VirtualRangeChangeableSettingsModule,
  VolumeChangeableSettingsModule,
} from "src/state/shared/CEX/types/settings";
import { ERRORS_MSGS } from "src/validation-schemas";
import { ObjectSchema, array, bool, number, object, string } from "yup";

const booleanDefaultSchema = bool()
  .typeError(ERRORS_MSGS.isRequired)
  .required(ERRORS_MSGS.isRequired);

const numberDefaultSchema = number()
  .typeError(ERRORS_MSGS.isRequired)
  .required(ERRORS_MSGS.isRequired);

const numberPositive = numberDefaultSchema.min(0, ERRORS_MSGS.isPositive);

const numberPositiveNotZero = numberPositive.notOneOf([0], ERRORS_MSGS.isNotZero);

export const decimalsValidSchema: ObjectSchema<DecimalsChangeableSettingsModule> = object({
  amount: numberDefaultSchema.integer(),
  price: numberDefaultSchema.integer(),
});

const pairValidSchema: ObjectSchema<PairChangeableSettingsModule> = object().shape(
  {
    dontTradePriceMin: numberPositive.when(["dontTradePriceMax"], ([v], schema) =>
      schema.max(v, "DTPMin should be less than DTPMax")
    ),
    dontTradePriceMax: numberPositive.when(["dontTradePriceMin"], ([v], schema) =>
      schema.min(v, "DTPMax must be greater than DTPMin")
    ),
    minExchangeAmount: numberDefaultSchema,
    dontCheckAmount: numberDefaultSchema,
  },
  [["dontTradePriceMax", "dontTradePriceMin"]]
);

const volumeValidSchema: ObjectSchema<VolumeChangeableSettingsModule> = object({
  tradePerDayMinUSD: numberPositive,
  modifyTradePerDayMinUSD: numberPositive,
  modifyDeltaBalanceQuoteUSD: numberPositive,
  modifyDeltaBalanceBase: numberPositive,
});

const tradeValidSchema: ObjectSchema<TradeChangeableSettingsModule> = object().shape(
  {
    minTrades: numberPositiveNotZero
      .integer()
      .when(["maxTrades"], ([v], schema) =>
        schema.max(v, "Trades Min should be less than Trades Max")
      ),
    maxTrades: numberPositiveNotZero
      .integer()
      .when(["minTrades"], ([v], schema) =>
        schema.min(v, "Trades Max must be greater than Trades Min")
      ),
    buyPercent: numberDefaultSchema,
    buyBeforeSellMin: numberPositive
      .integer()
      .when(["buyBeforeSellMax"], ([v], schema) =>
        schema.max(v, "BBs Min should be less than BBs Max")
      ),
    buyBeforeSellMax: numberPositive
      .integer()
      .when(["buyBeforeSellMin"], ([v], schema) =>
        schema.min(v, "BBs Max must be greater than BBs Min")
      ),
  },
  [
    ["minTrades", "maxTrades"],
    ["buyBeforeSellMin", "buyBeforeSellMax"],
  ]
);

const virtualRangeValidSchema: ObjectSchema<VirtualRangeChangeableSettingsModule> = object().shape(
  {
    cancelOrdersAccounts: array().required(ERRORS_MSGS.isRequired),
    enableBuyWall: booleanDefaultSchema,
    enableSellWall: booleanDefaultSchema,
    buyMinAmount: numberPositive.when(["buyMaxAmount"], ([v], schema) =>
      schema.max(v, "Buy min amount should be less than buy max amount")
    ),
    buyMaxAmount: numberPositive.when(["buyMinAmount"], ([v], schema) =>
      schema.min(v, "Buy max amount must be buy min amount")
    ),
    sellMinAmount: numberPositive.when(["sellMaxAmount"], ([v], schema) =>
      schema.max(v, "Sell min amount should be less than sell max amount")
    ),
    sellMaxAmount: numberPositive.when(["sellMinAmount"], ([v], schema) =>
      schema.min(v, "Sell max amount must be sell min amount")
    ),
    spread: numberDefaultSchema.integer(),
    diff: numberDefaultSchema,
    ratio: numberDefaultSchema,
  },
  [
    ["buyMinAmount", "buyMaxAmount"],
    ["sellMinAmount", "sellMaxAmount"],
  ]
);

const spreadDecompressionModeValidSchema = object({
  oneTimeLimit: numberPositive,
  dayLimit: numberPositive.when(["oneTimeLimit"], ([v], schema) =>
    schema.min(v, "Day limit amount must be greater than one time limit")
  ),
});

const spreadDecompressionValidSchema: ObjectSchema<SpreadDecompressionChangeableSettingsModule> =
  object({
    on: booleanDefaultSchema,
    alongMode: spreadDecompressionModeValidSchema,
    oppositeMode: spreadDecompressionModeValidSchema,
  });

const verifyOrderValidSchema: ObjectSchema<VerifyOrderChangeableSettingsModule> = object({
  on: booleanDefaultSchema,
  percent: string().typeError(ERRORS_MSGS.isRequired).required(ERRORS_MSGS.isRequired),
});

const candleCloserValidSchema: ObjectSchema<CandleCloserChangeableSettingsModule> = object({
  on: booleanDefaultSchema,
  period: numberDefaultSchema,
  outOfRange: booleanDefaultSchema,
});

const periodValidSchema: ObjectSchema<PeriodChangeableSettingsModule> = object({
  value: numberDefaultSchema.integer(),
});

const autoDiffValidSchema: ObjectSchema<AutoDiffChangeableSettingsModule> = object().shape(
  {
    on: booleanDefaultSchema,
    period: numberPositive,
    min_diff: numberPositive.when(["max_diff"], ([v], schema) =>
      schema.max(v, "Min diff should be less than buy max amount")
    ),
    max_diff: numberPositive.when(["min_diff"], ([v], schema) =>
      schema.min(v, "Max diff must be buy min amount")
    ),
    buy_limit_24h: numberPositive,
    sell_limit_24h: numberPositive,
    buy_limit_trade: numberPositive,
    sell_limit_trade: numberPositive,
  },
  [["min_diff", "max_diff"]]
);

export const settingsValidationSchema = object({
  decimals: decimalsValidSchema,
  pair: pairValidSchema,
  volume: volumeValidSchema,
  trade: tradeValidSchema,
  virtualRange: virtualRangeValidSchema,
  spreadDecompression: spreadDecompressionValidSchema,
  verifyOrder: verifyOrderValidSchema,
  candleCloser: candleCloserValidSchema,
  period: periodValidSchema,
  autoDiff: autoDiffValidSchema,
});
