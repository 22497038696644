import { Abilities } from "src/modules/abilities";

export const getToken = (): string => {
  const token = localStorage.getItem("token");

  if (token !== "undefined" && token !== null && token !== "") return token;
  return "";
};

export const getRefreshToken = (): string => {
  const token = localStorage.getItem("refreshToken");

  if (token !== "undefined" && token !== null && token !== "") return token;
  return "";
};

export const getAbilities = (): Abilities => {
  const abilities = localStorage.getItem("abilities");

  if (abilities !== "undefined" && abilities !== null && abilities !== "")
    return JSON.parse(abilities);
  return {};
};

export const setAccessToken = (token: string) => {
  localStorage.setItem("token", token);
};

export const setRefreshToken = (token: string) => {
  localStorage.setItem("refreshToken", token);
};

export const setAbilities = (abilities: Abilities) => {
  localStorage.setItem("abilities", JSON.stringify(abilities));
};

export const getTemporaryToken = (): string => {
  const token = sessionStorage.getItem("token");

  if (token !== "undefined" && token !== null && token !== "") return JSON.parse(token);
  return "";
};
