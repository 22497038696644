import "../helpers/yup/initializer";
import "../config/chartjs";
import "../config/dayjsConfig"; // Note: configs should be imported firstly (side-effects)
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React, { PropsWithChildren, ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import "./index.css";
import AppContext from "../context";
import { googleClientId } from "../environment/env";
import { appState } from "../state";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  },
});

// fixes bug with child elements for QueryClientProvider
declare module "react-query/types/react/QueryClientProvider" {
  interface QueryClientProviderProps {
    children?: ReactNode;
  }
}

export const Providers = ({ children }: PropsWithChildren) => (
  <QueryClientProvider client={queryClient}>
    <AppContext.Provider value={appState}>
      <GoogleOAuthProvider clientId={googleClientId}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>{children}</LocalizationProvider>
      </GoogleOAuthProvider>
    </AppContext.Provider>
  </QueryClientProvider>
);
