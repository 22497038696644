import { observer } from "mobx-react-lite";
import { NavigationMenu } from "src/components/NavigationMenu";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import useMediaQuery from "src/hooks/useMediaQuery";
import { useAppState } from "src/state";
import { ThemeProvider } from "styled-components";
import { Header } from "../components/Header";
import { darkTheme, lightTheme } from "../components/Themes";
import { ModalConsent } from "../components/shared/ModalConsent";
import { ToasterInit } from "../components/shared/Toaster";
import { useDarkMode } from "../hooks/UseDarkMode";
import { AppRouter } from "./AppRouter";
import * as styles from "./style";

const MOBILE_HEADER_QUERY = "(max-width: 800px)";

interface AppProps {
  theme?: "light" | "dark";
  skipLayout?: boolean;
}

export const App = observer(({ skipLayout, theme: propsTheme }: AppProps) => {
  const { userState } = useAppState();
  const { isLoggedIn } = userState;

  const { theme, themeToggler, mountedComponent } = useDarkMode();
  const isMobile = useMediaQuery(MOBILE_HEADER_QUERY);

  const themeMode = (propsTheme || theme) === "light" ? lightTheme : darkTheme;

  if (!mountedComponent) return <div />;

  return (
    <ThemeProvider theme={themeMode}>
      <styles.GlobalStyle theme={themeMode} />

      <styles.AppContent>
        {!skipLayout && <Header themeToggler={themeToggler} isMobile={isMobile} />}

        <styles.PageContent>
          {!skipLayout && !isMobile && isLoggedIn && <NavigationMenu />}

          <ErrorBoundary>
            <styles.PageContentContainer $skipLayout={skipLayout}>
              <AppRouter />
            </styles.PageContentContainer>
          </ErrorBoundary>
        </styles.PageContent>
      </styles.AppContent>

      <ModalConsent />

      <ToasterInit />
    </ThemeProvider>
  );
});
