import { ScrollSize } from "src/components/shared/shared";
import { CSS_VARS } from "src/helpers/cssVariables";
import styled, { createGlobalStyle } from "styled-components";
import { CrashPanel } from "../components/shared/ErrorBoundary/CrashPanel";

export const AppContent = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.mainBackgroundColor};

  display: flex;
  flex-direction: column;
  line-height: initial;
  font-size: 16px;
`;

export const PageContent = styled.div`
  height: 0px;

  display: flex;
  justify-content: space-between;

  flex: 1 1 auto;
`;

export const PageContentContainer = styled.div<{ $skipLayout?: boolean }>`
  position: relative;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${(props) => (props.$skipLayout ? 0 : "10px")};

  overflow: auto;

  ${ScrollSize}
`;

export const PageFallback = styled(CrashPanel)`
  margin: 30px;
  width: calc(100% - 30px);
`;

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: "Roboto", "San Francisco Pro", 'Inter';

    /* Custom scrollbar for Firefox */
    scrollbar-color: rgba(180, 180, 180, 0.5) transparent;
    scrollbar-width: thin;

    box-sizing: border-box;

    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }

  html, body {
    background-color: ${({ theme }) => theme.mainBackgroundColor};
    -webkit-font-smoothing: antialiased;
  }

  body {
    overflow-y: scroll;
  }

  .react-tooltip {
    overflow: visible;

    z-index: 1000;
  }

  :root {
    ${CSS_VARS.SIDE_MENU_SPACE}: 0px;
  }

  /* Custom scrollbar for Chrome/Edge/Safari */
  :not(textarea)::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }
   
  :not(textarea)::-webkit-scrollbar-track {
    background-color: inherit;
  }
   
  :not(textarea)::-webkit-scrollbar-thumb {
    background-color: rgb(180 180 180 / 50%);
    border-radius:  0.3em;
  }

  :not(textarea)::-webkit-scrollbar-thumb:hover {
    background-color: rgb(180 180 180 / 100%);
  }

  h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
  }

  h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
  }

  h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
  }

  h4 {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
  }

  h5 {
    display: block;
    font-size: 0.83em;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
  }

  h6 {
    display: block;
    font-size: 0.67em;
    margin-block-start: 2.33em;
    margin-block-end: 2.33em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
  }

  .sonner-root-toast.sonner-root-toast .toast-msg-content {
      white-space: pre-wrap !important;
      padding-top: 5px !important;
  }

  .sonner-root-toast.sonner-root-toast .toast-cancel-button {
      height: 100% !important;
      width: 100% !important;
      position: absolute !important;
      left: 0 !important;
      right: 0 !important;
      background: transparent !important;
  }

  .sonner-root-toast.sonner-root-toast .toast-action-button {
      position: absolute !important;
      top: 5px !important;
      right: 5px !important;
      opacity: 0 !important;
      padding: 0 !important;
      background: transparent !important;
      transition: opacity 0.3s ease !important;

      color: currentColor !important;
  }

  .sonner-root-toast.sonner-root-toast .toast-action-button {
      color: currentColor !important;
  }

  .sonner-root-toast.sonner-root-toast .toast-action-button rect {
      stroke: currentColor !important;
  }

  .sonner-root-toast.sonner-root-toast:hover .toast-action-button {
      opacity: 1 !important;
  }

  .sonner-root-toast.sonner-root-toast div[data-icon] {
      display: none !important;
  }
`;
